//libs
import { useEffect, useRef, useState } from 'react';

//hooks
import useOnScreen from '../../src/hooks/use-observer';

// components
import Card from '../components/UI/Card';
import ProjectBudget from '../components/ProjectBudget/ProjectBudget';
import BudgetToActuals from '../components/BudgetToActuals/BudgetToActuals';
import DrillDownExample from '../components/BudgetToActuals/DrillDownExample';
import { ReactComponent as BackgroundImg1 } from '../assets/svg_images/undraw_growing_re_olpi 1.svg';
import { ReactComponent as BackgroundImg2 } from '../assets/svg_images/undraw_data_report_re_p4so 1.svg';

// css
import styles from './Pages.module.css';

const UpdateBudget = ({ budget }) => {
  const [firstCardScrolledTo, setFirstDivScrolledTo] = useState(false);
  const [secondScrolledTo, setSecondDivScrolledTo] = useState(false);
  const [topTTScrolledTo, setTopTTScrolledTo] = useState(false);
  const [middleTTScrolledTo, setMiddleTTScrolledTo] = useState(false);
  const [secondCardScrolledTo, setSecondCardScrolledTo] = useState(false);
  const [b2aScrolledTo, setB2aScrolledTo] = useState(false);
  const [thirdCardScrolledTo, setThirdCardScrolledTo] = useState(false);
  const [drillDownScrolledTo, setDrillDownScrolledTo] = useState(false);

  const firstCardRef = useRef();
  const secondRef = useRef();
  const topTTRef = useRef();
  const middleTTRef = useRef();
  const secondCardRef = useRef();
  const b2aRef = useRef();
  const thirdCardRef = useRef();
  const drillDownRef = useRef();

  const isFirstCardVisible = useOnScreen(firstCardRef);
  const isSecondVisible = useOnScreen(secondRef);
  const isTopTTVisible = useOnScreen(topTTRef);
  const isMiddleTTVisible = useOnScreen(middleTTRef);
  const isSecondCardVisible = useOnScreen(secondCardRef);
  const isB2aVisible = useOnScreen(b2aRef);
  const isThirdCardVisible = useOnScreen(thirdCardRef);
  const isDrillDownVisible = useOnScreen(drillDownRef);

  useEffect(() => {
    if (isFirstCardVisible) setFirstDivScrolledTo(true);
  }, [isFirstCardVisible]);
  useEffect(() => {
    if (isSecondVisible) setSecondDivScrolledTo(true);
  }, [isSecondVisible]);
  useEffect(() => {
    if (isTopTTVisible) setTopTTScrolledTo(true);
  }, [isTopTTVisible]);
  useEffect(() => {
    if (isMiddleTTVisible) setMiddleTTScrolledTo(true);
  }, [isMiddleTTVisible]);
  useEffect(() => {
    if (isSecondCardVisible) setSecondCardScrolledTo(true);
  }, [isSecondCardVisible]);
  useEffect(() => {
    if (isB2aVisible) setB2aScrolledTo(true);
  }, [isB2aVisible]);
  useEffect(() => {
    if (isThirdCardVisible) setThirdCardScrolledTo(true);
  }, [isThirdCardVisible]);
  useEffect(() => {
    if (isDrillDownVisible) setDrillDownScrolledTo(true);
  }, [isDrillDownVisible]);

  return (
    <div className={styles['top-wrapper']}>
      {/* <div className={styles['background-img-1']}>
        <BackgroundImg1 />
      </div>
      <div className={styles['background-img-2']}>
        <BackgroundImg2 />
      </div> */}
      <div className={`${styles['page-frame']}`}>
        {/* <Card
          ref={firstCardRef}
          className={`${styles['card-frame']} ${
            firstCardScrolledTo && styles['animate']
          }`}
        > */}
        <h1
          ref={firstCardRef}
          className={`${styles['title']} ${styles['title__main']}`}
        >
          Instantly Update Your Project Budget with Stak
        </h1>
        {/* </Card> */}

        <ProjectBudget
          ref={secondRef}
          topTTRef={topTTRef}
          middleTTRef={middleTTRef}
          animateTTLeftMiddle={`${
            middleTTScrolledTo && styles['animate-tt-left-middle']
          }`}
          animateTTRightMiddle={`${
            middleTTScrolledTo && styles['animate-tt-right-middle']
          }`}
          animateTTLeft={`${topTTScrolledTo && styles['animate-tt-left']}`}
          animateTTRight={`${topTTScrolledTo && styles['animate-tt-right']}`}
          className={`${secondScrolledTo && styles['animate-card']}`}
          budget={budget}
        />

        <Card
          ref={secondCardRef}
          className={`${styles['card-frame']} ${
            secondCardScrolledTo && styles['animate']
          } ${styles['title']} ${styles['title__sub']} ${
            styles['title__sub-build']
          }`}
        >
          Stak streamlines project budget management with instant updates upon
          invoice approval, keeping you in the loop and on top of your project's
          financial progress in real-time.
        </Card>

        <BudgetToActuals
          ref={b2aRef}
          divisions={budget.divisions}
          className={`${b2aScrolledTo && styles['animate-card']}`}
        />
        <Card
          ref={thirdCardRef}
          className={`${styles['card-frame']} ${
            styles['card-frame__subtitle']
          } ${thirdCardScrolledTo && styles['animate']} ${styles['title']} ${
            styles['title__sub']
          }`}
        >
          Stak's budget tracking system allows for easy division breakdowns,
          allowing you to drill down into each subdivision with just one click;
          giving you a detailed and precise understanding of where every penny
          is going.
        </Card>

        <DrillDownExample
          ref={drillDownRef}
          className={`${drillDownScrolledTo && styles['animate-card']}`}
        />
      </div>
    </div>
  );
};

export default UpdateBudget;
