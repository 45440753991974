import React from 'react';

import styles from './BuildInvoice.module.css';

const BuildInvoiceInv = React.forwardRef((props, ref) => {
  const images = [
    require('../../assets/images/06-invoice.jpg'),
    require('../../assets/images/06-invoice-page1.jpg'),
    require('../../assets/images/06-invoice-page2.jpg'),
    require('../../assets/images/06-invoice-page3.jpg'),
    require('../../assets/images/06-invoice-page4.jpg'),
    require('../../assets/images/06-invoice-page5.jpg'),
  ];

  return (
    <div ref={ref} className={`${styles['build-invoice']} ${props.className}`}>
      <div className={styles['scroll-frame']}>
        {images.map((img) => {
          return (
            <img
              src={img}
              alt="example-invoice"
              className={styles['example-invoice__img']}
            />
          );
        })}
      </div>
    </div>
  );
});

export default BuildInvoiceInv;
