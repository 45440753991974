import React, { useState } from 'react';

//
import AnchorLinks from '../SideBarBudgetAnchorLinks/AnchorLinks';
import BudgetToActualsPlots from './BudgetToActualsPlots';

// helpers
import { formatNameForID } from '../../helpers/formatter';

// css
import styles from './BudgetToActuals.module.css';
const BudgetToActuals = React.forwardRef(({ divisions, className }, ref) => {
  const [clickedLink, setClickedLink] = useState('');

  const clickedLinkHandler = (number, name) => {
    setClickedLink(`${number}-${formatNameForID(name)}`);
  };

  return (
    <section ref={ref} className={`${styles['b2a-parent']} ${className}`}>
      <AnchorLinks
        style={{ opacity: 0 }}
        isMobile={true}
        divisions={divisions}
        onClickAnchor={clickedLinkHandler}
      />
      <BudgetToActualsPlots divisions={divisions} clickedLink={clickedLink} />
    </section>
  );
});

export default BudgetToActuals;
