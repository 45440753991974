// libs
import { useMediaQuery } from 'react-responsive';
import React from 'react';

// css
import styles from './AnchorLinks.module.css';
// import 'react-tooltip/dist/react-tooltip.css';

import TooltipStatic from '../UI/TooltipStatic';

//svg components
import { ReactComponent as GeneralConditions } from '../../assets/icons/general_conditions.svg';
import { ReactComponent as SiteWork } from '../../assets/icons/site_work.svg';
import { ReactComponent as Concrete } from '../../assets/icons/concrete.svg';
import { ReactComponent as Masonry } from '../../assets/icons/masonry.svg';
import { ReactComponent as Metals } from '../../assets/icons/metals.svg';
import { ReactComponent as WoodPlastic } from '../../assets/icons/wood_and_plastic.svg';
import { ReactComponent as ThermalProtection } from '../../assets/icons/thermal_and_moisture_protection.svg';
import { ReactComponent as WindowsDoors } from '../../assets/icons/windows_and_doors.svg';
import { ReactComponent as Finishes } from '../../assets/icons/finishes.svg';
import { ReactComponent as Specialties } from '../../assets/icons/specialties.svg';
import { ReactComponent as Equipment } from '../../assets/icons/equipment.svg';
import { ReactComponent as Furnishings } from '../../assets/icons/furnishings.svg';
import { ReactComponent as SpecialConstruction } from '../../assets/icons/specialty_construction.svg';
import { ReactComponent as ConveyingSystems } from '../../assets/icons/conveying_systems.svg';
import { ReactComponent as Mechanical } from '../../assets/icons/mechanical.svg';
import { ReactComponent as Electrical } from '../../assets/icons/electrical.svg';

const icons = [
  <GeneralConditions width={24} height={24} />,
  <SiteWork width={24} height={24} />,
  <Concrete width={24} height={24} />,
  <Masonry width={24} height={24} />,
  <Metals width={24} height={24} />,
  <WoodPlastic width={24} height={24} />,
  <ThermalProtection width={24} height={24} />,
  <WindowsDoors width={24} height={24} />,
  <Finishes width={24} height={24} />,
  <Specialties width={24} height={24} />,
  <Equipment width={24} height={24} />,
  <Furnishings width={24} height={24} />,
  <SpecialConstruction width={24} height={24} />,
  <ConveyingSystems width={24} height={24} />,
  <Mechanical width={24} height={24} />,
  <Electrical width={24} height={24} />,
];

const AnchorLinks = React.forwardRef((props, ref) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <div className={styles['links-parent-frame']}>
      <TooltipStatic
        content="Easily navigate your budget with Stak's clickable divisions, allowing for quick scrolling to specific sections of the budget. Give it a try in the demo, where the first two divisions are pre-populated. Try it out now!"
        direction="left"
        ref={ref}
        animateTT={props.aniRight}
        style={props.style}
      >
        <div className={styles['links-scroll-frame']}>
          <ul className={styles['links-content-frame']}>
            {props.divisions.map((el, i) => (
              <li
                key={el.number}
                onClick={() => {
                  props.onClickAnchor(el.number, el.name);
                }}
              >
                {el.number}&nbsp;
                {(isMobile || props.isMobile) && icons[+el.number - 1]}&nbsp;
                {!isMobile && !props.isMobile && el.name}
              </li>
            ))}
          </ul>
        </div>
      </TooltipStatic>
    </div>
  );
});

export default AnchorLinks;
