import styles from './B2ALegend.module.css';

const Legend = ({ leftTitle, rightTitle }) => {
  return (
    <section className={styles['legend']}>
      <div className={styles['legend__item']}>
        <div className={styles['legend__bar-actuals']}></div>
        <span>{leftTitle}</span>
      </div>
      <div className={styles['legend__item']}>
        <div className={styles['legend__bar-budget']}></div>
        <span>{rightTitle}</span>
      </div>
    </section>
  );
};

export default Legend;
