import React from 'react';
import styles from './B2APlotTile.module.css';

const B2APlotTile = React.forwardRef(({ title, number, children, id }, ref) => {
  return (
    <div id={id} ref={ref} className={styles['plot-tile-row']}>
      <div className={styles['plot-parent-tile']}>
        <div className={styles['plot-title']}>
          <span>{title}</span>
          <span>{number}</span>
        </div>
        <div className={styles['plot-tile']}>{children}</div>
      </div>
    </div>
  );
});

export default B2APlotTile;
