import { ReactComponent as TopEllipse } from '../../assets/hero/top-middle-ellipse.svg';
import { ReactComponent as BottromRightEllipse } from '../../assets/hero/bottom-right-ellipse.svg';
import { ReactComponent as BottomLeftEllipse } from '../../assets/hero/bottom-left-ellipse.svg';

import { useMediaQuery } from 'react-responsive';

import styles from './HeroModern.module.css';

const HeroModern = () => {
  const isMobile76 = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <section className={styles['main-hero-frame']}>
      <TopEllipse
        width={isMobile76 ? '30rem' : '46rem'}
        height="40rem"
        id="top-ellipse"
        className={styles['ellipse__top']}
      />
      <BottomLeftEllipse
        width={isMobile76 ? '30rem' : '46rem'}
        height="30rem"
        id="bottom-left-ellipse"
        className={styles['ellipse__bottomLeft']}
      />
      <BottromRightEllipse
        width={isMobile76 ? '30rem' : '50rem'}
        height="40rem"
        id="bottom-right-ellipse"
        className={styles['ellipse__bottomRight']}
      />
      <div className={styles['headline']}>
        <h1>Stak</h1>
        <h2>Automated Invoicing for Construction</h2>
        <h3>
          Streamline project finances with Stak - automated invoice processing
          and budget tracking for effortless financial management.
        </h3>
      </div>
      <div className={styles['hero-image']}></div>
    </section>
  );
};

export default HeroModern;
