import React, { Fragment, useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as SlideToggleOff } from '../../assets/ui_elements/slide_off.svg';
import { ReactComponent as SlideToggleOn } from '../../assets/ui_elements/slide_on.svg';
import ProcessInvoiceContext from '../../store/process-invoice-context';

import styles from './SlideToggle.module.css';

const SlideToggle = ({ ctxId, startVal }) => {
  const isMobile76 = useMediaQuery({ query: `(max-width: 760px)` });
  const isMobile96 = useMediaQuery({ query: `(max-width: 960px)` });

  const invoiceCtx = useContext(ProcessInvoiceContext);
  const [isOn, setIsOn] = useState(startVal ? startVal : false);
  const clickHandler = () => {
    setIsOn((prevState) => !prevState);
    switch (ctxId) {
      case 'lineItems':
        invoiceCtx.onToggleLineItems();
      // put other context handlers here
    }
  };

  const width = isMobile96 && isMobile76 ? 30 : isMobile96 ? 40 : 42;

  return (
    <Fragment>
      <div onClick={clickHandler} className={styles['slider']}>
        {isOn && <SlideToggleOn width={width} />}
        {!isOn && <SlideToggleOff width={width} />}
      </div>
    </Fragment>
  );
};

export default SlideToggle;
