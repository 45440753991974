import styles from './Pages.module.css';

import { ReactComponent as BackgroundImg } from '../assets/svg_images/undraw_receipt_re_fre3 2.svg';
import { useEffect, useRef, useState } from 'react';
import useOnScreen from '../hooks/use-observer';
import Card from '../components/UI/Card';
import BuildInvoiceInv from '../components/BuildInvoice/BuildInvoiceInv';

const BuildInvoice = () => {
  const [firstCardScrolledTo, setFirstDivScrolledTo] = useState(false);
  const [secondScrolledTo, setSecondDivScrolledTo] = useState(false);

  const firstCardRef = useRef();
  const secondRef = useRef();

  const isFirstCardVisible = useOnScreen(firstCardRef);
  const isSecondVisible = useOnScreen(secondRef);

  useEffect(() => {
    if (isFirstCardVisible) setFirstDivScrolledTo(true);
  }, [isFirstCardVisible]);
  useEffect(() => {
    if (isSecondVisible) setSecondDivScrolledTo(true);
  }, [isSecondVisible]);

  return (
    <div className={styles['top-wrapper']}>
      <div className={styles['background-img-12']}>
        <BackgroundImg />
      </div>
      <div className={`${styles['page-frame']}`}>
        <h1 className={`${styles['title']} ${styles['title__main']}`}>
          Automatic Invoicing with a Professional Touch
        </h1>
        <Card
          ref={firstCardRef}
          className={`${styles['card-frame']} 
          ${styles['title']} 
          ${styles['title__sub']} ${firstCardScrolledTo && styles['animate']}`}
        >
          Stak streamlines the invoice building process, organizing all costs
          for a polished final product, impressing clients and ensuring timely
          payment with automatic reminders. It also keeps record of invoices and
          receipts for easy client record keeping.
        </Card>
        <h2
          className={`${styles['title']} ${styles['title__sub']} ${styles['title__sub-build']}`}
        >
          Here is an example of a real invoice!
        </h2>
        <BuildInvoiceInv
          ref={secondRef}
          className={`${secondScrolledTo && styles['animate']}`}
        />
      </div>
    </div>
  );
};

export default BuildInvoice;
