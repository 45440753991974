import styles from './Footer.module.css';

import { ReactComponent as Logo } from '../../assets/logo/Stak Logo.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/Message.svg';
import { ReactComponent as LinkedIn } from '../../assets/icons/iconmonstr-linkedin-3.svg';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const Footer = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const navigate = useNavigate();

  return (
    <div className={styles['footer-main']}>
      <div className={styles['upper-container']}>
        <Logo
          className={styles['logo']}
          onClick={() => navigate('/')}
          width={isMobile ? 100 : 150}
        />
        <div className={styles['get-in-touch-box']}>
          <h4>Get In Touch</h4>
          <section onClick={props.onShowModal}>
            <MessageIcon width={isMobile ? 18 : 24} />
            <h5>Contact Us</h5>
          </section>
          <a href="https://www.linkedin.com/company/stak-technologies/">
            <LinkedIn width={isMobile ? 16 : 24} className={styles['svg']} />
          </a>
        </div>
      </div>
      <h5 className={styles['copyright']}>
        &copy; Stak Technologies, Inc. 2023
      </h5>
    </div>
  );
};

export default Footer;
