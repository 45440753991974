import React, { useEffect, useRef } from 'react';

import { ReactComponent as GeneralConditionsPlot } from '../../assets/svg_images/01-b2a_blu-green 2.svg';
import { ReactComponent as SiteWorkPlot } from '../../assets/svg_images/02-b2a-blu-green 1.svg';
import { ReactComponent as ConcretePlot } from '../../assets/svg_images/03-b2a-blu-green 2.svg';
import { ReactComponent as MetalsPlot } from '../../assets/svg_images/05-b2a-blu-green 2.svg';
import { formatNameForID } from '../../helpers/formatter';

import Legend from './B2ALegend';
import B2APlotTile from './B2APlotTile';

import styles from './BudgetToActualsPlots.module.css';

const plots = [
  <GeneralConditionsPlot className={styles['svg']} />,
  <SiteWorkPlot className={styles['svg']} />,
  <ConcretePlot className={styles['svg']} />,
  <MetalsPlot className={styles['svg']} />,
];

const BudgetToActualsPlots = ({ divisions, clickedLink }) => {
  const scrollableRef = useRef();
  // Scroll to the anchor link point only when the anchor link
  // that was clicked changes.
  useEffect(() => {
    if (scrollableRef.current) {
      const scrollToElement = document.getElementById(`${clickedLink}__plot`);
      if (scrollToElement) {
        const topScrollAnchor = document
          .getElementById('01-general_conditions__plot')
          .getBoundingClientRect().top;
        const parentScrollElement =
          document.getElementById('scroll-frame__plot');
        parentScrollElement.scroll({
          top: scrollToElement.getBoundingClientRect().top - topScrollAnchor,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [clickedLink]);
  return (
    <div className={styles['plots-parent-frame']}>
      <Legend leftTitle="Actual Costs" rightTitle="Budget" />

      <section
        id="scroll-frame__plot"
        ref={scrollableRef}
        className={styles['scroll-frame']}
      >
        {divisions.map((division, i) => {
          if (i === 3 || i > 4) return;
          else
            return [
              <B2APlotTile
                id={`${division.number}-${formatNameForID(
                  division.name
                )}__plot`}
                className={styles['svg-style']}
                key={i}
                title={division.name}
                number={division.number}
              >
                {i === 4 ? plots[i - 1] : plots[i]}
              </B2APlotTile>,
            ];
        })}
      </section>
    </div>
  );
};

export default BudgetToActualsPlots;
