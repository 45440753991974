import styles from './ScheduleDemo.module.css';

const ScheduleDemo = (props) => {
  return (
    <div className={styles['main']}>
      <h2>
        <span onClick={props.onShowModal}>Contact Us</span> to Schedule a Demo,
        We Would Love to Hear From You!
      </h2>
    </div>
  );
};

export default ScheduleDemo;
