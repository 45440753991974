import React from 'react';

import styles from './Card.module.css';

const Card = React.forwardRef((props, ref) => {
  const classes = `${styles.card} ${props.className}`;
  return (
    <div ref={ref} className={classes}>
      {props.children}
    </div>
  );
});

export default Card;
