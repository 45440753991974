import { Fragment } from 'react';
import InputProcessInvoice from '../UI/FormUI/InputProcesInvoice';
import InputSlideToggle from '../UI/FormUI/InputSlideToggle';
import TextAreaProcessInvoice from '../UI/FormUI/TextAreaProcesInvoice';
import styles from './Item.module.css';

const Item = (props) => {
  const { num, description, costCode, total } = props;
  return (
    <Fragment>
      <div className={styles['item-title']}>
        <p className={styles['item-title__text']}>Line Item {num}</p>
        <div className={styles['line-sep']} />
      </div>
      <TextAreaProcessInvoice
        label="Description"
        input={{ id: 'description', type: 'text', modelval: description }}
      />
      <InputProcessInvoice
        label="Cost Code"
        input={{ id: 'cost-code', type: 'text', modelval: costCode }}
      />
      <InputProcessInvoice
        label="Total Amount"
        isCurrency={true}
        input={{ id: 'cost-code', type: 'text', modelval: total }}
      />
      <InputSlideToggle
        label="Change Order"
        ctxId="changeOrder"
        input={{
          id: 'change-order',
        }}
      />
    </Fragment>
  );
};

export default Item;
