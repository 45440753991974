import SlideToggle from '../SlideToggle';
import styles from './InputSlideToggle.module.css';

const InputSlideToggle = ({ label, input, ctxId }) => {
  return (
    <div className={styles['input-box']}>
      <label className={styles['label']} htmlFor={input.id}>
        {label}
      </label>
      <SlideToggle startVal={input.startVal} ctxId={ctxId} />
    </div>
  );
};

export default InputSlideToggle;
