import React, { useState } from 'react';

const ProcessInvoiceContext = React.createContext({
  lineItems: false,
  credit: false,
  changeOrder: false,
  addToInvoice: true,
  onToggleLineItems: () => {},
  onToggleCredit: () => {},
  onToggleChangeOrder: () => {},
  onToggleAddToInvoice: () => {},
});

export const ProcessInvoiceContextProvider = (props) => {
  const [lineItems, setLineItems] = useState(false);
  const [credit, setCredit] = useState(false);
  const [changeOrder, setChangeOrder] = useState(false);
  const [addToInvoice, setAddToInvoice] = useState(true);

  const lineItemsHandler = () => {
    setLineItems((prevState) => !prevState);
  };
  const creditHandler = () => {
    setCredit((prevState) => !prevState);
  };
  const changeOrderHandler = () => {
    setChangeOrder((prevState) => !prevState);
  };
  const addToInvoiceHandler = () => {
    setAddToInvoice((prevState) => !prevState);
  };

  return (
    <ProcessInvoiceContext.Provider
      value={{
        lineItems,
        credit,
        changeOrder,
        addToInvoice,
        onToggleLineItems: lineItemsHandler,
        onToggleCredit: creditHandler,
        onToggleChangeOrder: changeOrderHandler,
        onToggleAddToInvoice: addToInvoiceHandler,
      }}
    >
      {props.children}
    </ProcessInvoiceContext.Provider>
  );
};

export default ProcessInvoiceContext;
