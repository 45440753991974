import React from 'react';

import { ReactComponent as CloseCross } from '../../assets/icons/modal-close.svg';

import Card from './Card';

import styles from './Modal.module.css';
import ContactForm from '../ContactForm/ContactForm';
import { useMediaQuery } from 'react-responsive';

const Modal = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const widthHeight = isMobile ? 20 : 32;

  return (
    <React.Fragment>
      <div
        id="modal-backdrop"
        className={styles['modal-overlay']}
        onClick={() => props.onShowModal(false)}
      ></div>
      <div className={styles['modal']}>
        <Card
          className={styles['modal-content']}
          onClick={() => props.onShowModal(true)}
        >
          <header className={styles['modal-header']}>
            <CloseCross
              width={widthHeight}
              height={widthHeight}
              className={styles['close-modal']}
              onClick={() => props.onShowModal(false)}
            />
          </header>
          <main>
            <ContactForm onClose={() => props.onShowModal(false)} />
          </main>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Modal;
