import ApproverAdd from './ApproverAdd';
import Approver from './Approvers';
import styles from './InputApprovers.module.css';

const InputApprovers = ({ label, input }) => {
  return (
    <div className={styles['input-box']}>
      <label className={styles['label']} htmlFor={input.id}>
        {label}
      </label>
      <div className={styles['input']}>
        {input.approvers.map((approver, i) => {
          return <Approver key={i} name={approver} />;
        })}
        <ApproverAdd />
      </div>
    </div>
  );
};

export default InputApprovers;
