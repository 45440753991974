import { createSlice, configureStore, current } from '@reduxjs/toolkit';

// helpers
import refactorBudget from '../helpers/objectRefactorForState';

// fetch data from the server and set that data as the initial state
// This JSON is what would be returned from the database
// This will also be in some state and get updated, modified accordingly

const initialBudgetState = {};

const budgetSlice = createSlice({
  name: 'budget',
  initialState: initialBudgetState,
  reducers: {
    refactorBudgetObj(state, action) {
      return refactorBudget(action.payload);
    },
    toggleLine(state, action) {
      const { payload } = action;
      const { isShow, divNum, subdivNum, itemNum } = payload;
      state[divNum].subdivisions[subdivNum].items[itemNum].isShowing = isShow;
    },
    toggleCollapse(state) {
      state.isCollapsed = !state.isCollapsed;
    },
    updateValue(state, action) {
      const { payload } = action;
      const { value, divNum, subdivNum, itemNum } = payload;

      const item = state[divNum].subdivisions[subdivNum].items[itemNum];
      item.value = value;
    },
    updateTotals(state, action) {
      const { payload } = action;
      const { divNum, subdivNum } = payload;

      const division = state[divNum];
      const subdivision = state[divNum].subdivisions[subdivNum];

      subdivision.total = Object.values(subdivision.items)
        .map((item) => item.isShowing && +item.value)
        .reduce((acc, curr) => acc + curr, 0);

      division.total = Object.values(division.subdivisions).reduce(
        (sum, val) => sum + (+val.total || 0),
        0
      );

      state.total = Object.values(state).reduce(
        (sum, val) => sum + (+val.total || 0),
        0
      );
    },
  },
});

const store = configureStore({
  reducer: budgetSlice.reducer,
});

export const budgetActions = budgetSlice.actions;
export default store;
