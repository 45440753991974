// libs
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components
import NavComponent from './components/Navbar/NavComponent';
import Modal from './components/UI/Modal';
import ContactForm from './components/ContactForm/ContactForm';
import Footer from './components/Footer/Footer';

// redux
import { budgetActions } from './store';

// pages
import Home from './pages/Home';
import UpdateBudget from './pages/UpdateBudget';
import ProcessInvoice from './pages/ProcessInvoicePage';

// helpers
import { addSectionTotals } from './helpers/modifyCostCodeObj';
import { ProcessInvoiceContextProvider } from './store/process-invoice-context';
import ScheduleDemo from './components/Footer/ScheduleDemo/ScheduleDemo';
import BuildInvoice from './pages/BuildInvoice';

// This JSON is what would be returned from the database
// This will also be in some state and get updated, modified accordingly
const projectBudget = addSectionTotals({
  format: 'Custom',
  currency: 'USD',
  isCollapsed: false,
  divisions: [
    {
      name: 'General Conditions',
      number: '01',
      subdivisions: [
        {
          name: 'Management',
          number: '1.1',
          items: [
            { name: 'Project Supervision', number: '1.110', value: '76200' },
            { name: 'Project Management', number: '1.120', value: '' },
            { name: 'Project Assistant', number: '1.130', value: '' },
          ],
        },
        {
          name: 'Professional Fees',
          number: '1.2',
          items: [
            { name: 'Architectural Design', number: '1.210', value: '' },
            { name: 'Engineering', number: '1.220', value: '' },
            { name: 'Interior Design', number: '1.230', value: '' },
            { name: 'Landscape Design', number: '1.240', value: '' },
            { name: 'Surveying', number: '1.250', value: '5000' },
            { name: 'Blueprints', number: '1.260', value: '' },
          ],
        },
        {
          name: 'Equipment',
          number: '1.3',
          items: [
            { name: 'Equipment Rental', number: '1.310', value: '2000' },
            { name: 'Job Shack', number: '1.320', value: '' },
            { name: 'Small Tools', number: '1.330', value: '1500' },
            { name: 'Temporary Fence Rental', number: '1.340', value: '1250' },
            { name: 'Temporary Generator', number: '1.350', value: '' },
            { name: 'Truck Rental', number: '1.360', value: '3000' },
            { name: 'Travel Per Diem', number: '1.370', value: '' },
          ],
        },
      ],
    },
    {
      name: 'Site Work',
      number: '02',
      subdivisions: [
        {
          name: 'Demolition',
          number: '2.1',
          items: [
            {
              name: 'Hazardous Material Abatement',
              number: '2.110',
              value: '14865',
            },
            { name: 'Tank Removal', number: '2.120', value: '' },
            { name: 'Building Demolition', number: '2.130', value: '20049' },
          ],
        },
        {
          name: 'Earthwork',
          number: '2.2',

          items: [
            { name: 'Mobilization', number: '2.205', value: '' },
            { name: 'Site Clearing', number: '2.210', value: '' },
            { name: 'Excavation', number: '2.220', value: '7860' },
            { name: 'Backfill', number: '2.230', value: '7860' },
            { name: 'Compaction', number: '2.240', value: '' },
            { name: 'Final Grade', number: '2.250', value: '' },
            { name: 'Import and Export Fill', number: '2.260', value: '21300' },
            {
              name: 'Drilling, Piles, and Soil Nails',
              number: '2.270',
              value: '',
            },
            { name: 'Erosion Control', number: '2.280', value: '' },
            { name: 'Temporary Road', number: '2.290', value: '2500' },
          ],
        },
        {
          name: 'Hauling and Waste',
          number: '2.3',

          items: [{ name: 'Dump Fees', number: '2.310', value: '7500' }],
        },
        {
          name: 'Drainage',
          number: '2.4',

          items: [
            { name: 'Drain Rock', number: '2.410', value: '' },
            { name: 'Filter Fabric', number: '2.420', value: '' },
            { name: 'Foundation Drains', number: '2.430', value: '5000' },
            { name: 'Storm Sewer Materials', number: '2.440', value: '3968' },
          ],
        },
        {
          name: 'Utilities',
          number: '2.5',

          items: [
            { name: 'Fire Department Connection', number: '2.565', value: '' },
          ],
        },
      ],
    },
    { name: 'Concrete', number: '03' },
    { name: 'Masonry', number: '04' },
    { name: 'Metals', number: '05' },
    { name: 'Wood & Plastic', number: '06' },
    { name: 'Thermal & Moisture Protection', number: '07' },
    { name: 'Windows & Doors', number: '08' },
    { name: 'Finishes', number: '09' },
    { name: 'Specialties', number: '10' },
    { name: 'Equipment', number: '11' },
    { name: 'Furnishings', number: '12' },
    { name: 'Special Construction', number: '13' },
    { name: 'Conveying Systems', number: '14' },
    { name: 'Mechanical', number: '15' },
    { name: 'Electrical', number: '16' },
  ],
});

const App = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const dispatch = useDispatch();
  dispatch(budgetActions.refactorBudgetObj(projectBudget));

  return (
    <React.Fragment>
      <ProcessInvoiceContextProvider>
        <NavComponent onShowModal={() => setShowContactModal(true)} />
        {showContactModal && (
          <Modal
            onShowModal={(isShowModal) => setShowContactModal(isShowModal)}
          >
            <ContactForm />
          </Modal>
        )}

        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route
            path="/features/update-budgets"
            element={
              <UpdateBudget
                onShowModal={() => setShowContactModal(true)}
                budget={projectBudget}
              />
            }
          />
          <Route
            path="/features/process-invoices"
            element={
              <ProcessInvoice onShowModal={() => setShowContactModal(true)} />
            }
          />
          <Route path="/features/build-invoice" element={<BuildInvoice />} />
        </Routes>
        <ScheduleDemo onShowModal={() => setShowContactModal(true)} />
        <Footer onShowModal={() => setShowContactModal(true)} />
      </ProcessInvoiceContextProvider>
    </React.Fragment>
  );
};

export default App;
