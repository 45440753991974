import React, { Fragment, useState, useEffect } from 'react';

import './Tooltip.css';

const TooltipStatic = React.forwardRef((props, ref) => {
  return (
    <div className="wrapper-scroll">
      {props.children}
      {(props.show || true) && (
        <div
          style={props.style}
          ref={ref}
          id="tooltip"
          className={`tooltip-tip ${props.direction || 'top'} ${
            props.animateTT
          }`}
        >
          {props.content}
        </div>
      )}
    </div>
  );
});

export default TooltipStatic;
