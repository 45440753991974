// libs
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// svg
import { ReactComponent as AddBudgetItem } from '../../../assets/ui_elements/AddBudgetItem.svg';
import { ReactComponent as RemoveBudgetItem } from '../../../assets/ui_elements/RemoveBudgetItem.svg';
import { budgetActions } from '../../../store';

// css
import styles from './ToggleBudgetInput.module.css';

const ToggleBudgetInput = ({ divNumber, subdivNumber, itemNumber }) => {
  const isMobile76 = useMediaQuery({ query: `(max-width: 760px)` });
  const isMobile96 = useMediaQuery({ query: `(max-width: 960px)` });
  const isMobile120 = useMediaQuery({ query: `(max-width: 1200px)` });
  const dispatch = useDispatch();
  const removeHandler = (isShow) => {
    dispatch(
      budgetActions.toggleLine({
        isShow,
        divNum: divNumber,
        subdivNum: subdivNumber,
        itemNum: itemNumber,
      })
    );
    dispatch(
      budgetActions.updateTotals({
        divNum: divNumber,
        subdivNum: subdivNumber,
      })
    );
  };

  const width = isMobile120 ? (isMobile96 ? (isMobile76 ? 20 : 22) : 24) : 28;

  return (
    <div className={styles['toggle-buttons-parent']}>
      <div className={styles['toggle-buttons']}>
        <RemoveBudgetItem onClick={() => removeHandler(false)} width={width} />
        <AddBudgetItem onClick={() => removeHandler(true)} width={width} />
      </div>
    </div>
  );
};

export default ToggleBudgetInput;
