/**
 This function adds a 'total' to each division and subdivision within the costCodesObj.
 The 'total' calculates the sum of the values within the items array of the subdivisions and the total of the subdivisions of the divisions.
@function
@param {Object} costCodesObj - an object containing the cost code divisions and subdivisions
@returns {Object} - the original costCodesObj with the added 'total' on divisions and subdivisions
*/
export const addSectionTotals = (costCodesObj) => {
  costCodesObj.divisions?.forEach((div) => {
    div.subdivisions?.forEach((subdiv) => {
      subdiv['total'] = subdiv.items.reduce(
        (sum, val) => sum + (+val.value || 0),
        0
      );
      subdiv.items.map((item) => {
        item['isShowing'] = item.value !== '';
      });
    });

    div['total'] = div.subdivisions?.reduce(
      (sum, val) => sum + (+val.total || 0),
      0
    );

    // this is there to catch missing subdivisions in the object
  });
  costCodesObj['total'] = costCodesObj.divisions.reduce(
    (sum, val) => sum + (val.total || 0),
    0
  );
  return costCodesObj;
};
