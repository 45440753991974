// libs
import { Fragment } from 'react';

// redux
import { useSelector } from 'react-redux';

// css
import styles from './BudgetSubDivTitle.module.css';

// helpers
import { formatNumber } from '../../../helpers/formatter';

const BudgetSubDivTitle = ({
  divNumber,
  subdivNumber,
  subdivName,
  className,
  id,
  isAllEmpty,
}) => {
  let total = useSelector(
    (state) => state[+divNumber]?.subdivisions[+subdivNumber].total
  );
  total = total ? total : 0;

  const isCollapsed = useSelector((state) => state.isCollapsed);

  return (
    <Fragment>
      {(!isAllEmpty || isCollapsed) && (
        <div id={id} className={className}>
          <h3 className={styles['title']}>
            {subdivNumber} - {subdivName}
          </h3>
          <span id={`horizontal-line-${subdivNumber}`}>
            Total: $ {formatNumber(total, true, true)}
          </span>
        </div>
      )}
    </Fragment>
  );
};

export default BudgetSubDivTitle;
