import { formatNumber } from '../../helpers/formatter';
import Button from '../UI/Button';
import Item from './Item';
import styles from './LineItems.module.css';

const LineItems = ({ lineItems }) => {
  return (
    <div className={styles['line-item-box']}>
      {lineItems.map((item, i) => {
        return (
          <Item
            key={i}
            num={i + 1}
            description={item.description}
            costCode={item.costCode}
            total={item.total}
            changeOrder={item.changeOrder}
            isCurrency={item.isCurrency}
          />
        );
      })}
      <Button className={styles['button']} buttonText="Add Line Item" />
    </div>
  );
};

export default LineItems;
