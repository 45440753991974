import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import useOnScreen from '../../hooks/use-observer';

import styles from './Features.module.css';
import animationStyles from './FeaturesAni.module.css';

import FeatureTile from './FeatureTile';

const Features = () => {
  const [firstDivScrolledTo, setFirstDivScrolledTo] = useState(false);
  const [secondDivScrolledTo, setSecondDivScrolledTo] = useState(false);
  const [thirdDivScrolledTo, setThirdDivScrolledTo] = useState(false);

  const isMobile96 = useMediaQuery({ query: `(max-width: 960px)` });
  const isMobile120 = useMediaQuery({ query: `(max-width: 1200px)` });

  const firstDivRef = useRef();
  const secondDivRef = useRef();
  const thirdDivRef = useRef();

  const isFirstVisible = useOnScreen(firstDivRef);
  const isSecondVisible = useOnScreen(secondDivRef);
  const isThirdVisible = useOnScreen(thirdDivRef);

  useEffect(() => {
    if (isFirstVisible) setFirstDivScrolledTo(true);
  }, [isFirstVisible]);
  useEffect(() => {
    if (isSecondVisible) setSecondDivScrolledTo(true);
  }, [isSecondVisible]);
  useEffect(() => {
    if (isThirdVisible) setThirdDivScrolledTo(true);
  }, [isThirdVisible]);

  return (
    <div className={styles['features-main-box']}>
      <FeatureTile
        ref={firstDivRef}
        title="Stak streamlines invoicing with machine learning"
        featureNum={0}
        height={isMobile120 ? (isMobile96 ? '200' : '250') : '338'}
        width={isMobile120 ? (isMobile96 ? '244' : '306') : '413'}
        goto="process-invoices"
        cardAni={`${firstDivScrolledTo && animationStyles['fade-into-view']}`}
        titleAni={`${
          firstDivScrolledTo && animationStyles['scroll-into-view-title']
        }`}
        textAni={`${
          firstDivScrolledTo && animationStyles['scroll-into-view-text']
        }`}
        buttonAni={`${
          firstDivScrolledTo && animationStyles['scroll-into-view-text']
        }`}
        svgAni={`${firstDivScrolledTo && animationStyles['svg-fade-in']}`}
      />

      <FeatureTile
        ref={secondDivRef}
        title="Stak keeps project budgets updated in real-time"
        featureNum={1}
        height={isMobile120 ? (isMobile96 ? '360' : '444') : 600}
        width={isMobile120 ? (isMobile96 ? '450' : '555') : 750}
        goto="update-budgets"
        cardAni={`${secondDivScrolledTo && animationStyles['fade-into-view']}`}
        titleAni={`${
          secondDivScrolledTo && animationStyles['scroll-into-view-title']
        }`}
        textAni={`${
          secondDivScrolledTo && animationStyles['scroll-into-view-text']
        }`}
        buttonAni={`${
          secondDivScrolledTo && animationStyles['scroll-into-view-text']
        }`}
        svgAni={`${secondDivScrolledTo && animationStyles['svg-fade-in-left']}`}
      />

      <FeatureTile
        ref={thirdDivRef}
        title="Build, send, and follow up on the client's invoice"
        featureNum={2}
        height={isMobile120 ? (isMobile96 ? '420' : '518') : 700}
        width={isMobile120 ? (isMobile96 ? '446' : '551') : 658}
        goto="build-invoice"
        cardAni={`${thirdDivScrolledTo && animationStyles['fade-into-view']}`}
        titleAni={`${
          thirdDivScrolledTo && animationStyles['scroll-into-view-title']
        }`}
        textAni={`${
          thirdDivScrolledTo && animationStyles['scroll-into-view-text']
        }`}
        buttonAni={`${
          thirdDivScrolledTo && animationStyles['scroll-into-view-text']
        }`}
        svgAni={`${
          thirdDivScrolledTo && animationStyles['svg-fade-in-bottom']
        }`}
      />
    </div>
  );
};

export default Features;
