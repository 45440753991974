//lib
import React, { useState } from 'react';

// components
import TitleCard from './TitleBanner/Title';
import AnchorLinks from '../SideBarBudgetAnchorLinks/AnchorLinks';
import Budget from './Budget/Budget';

// css
import styles from './ProjectBudget.module.css';

// helpers
import { formatNameForID } from '../../helpers/formatter';

const ProjectBudget = React.forwardRef(
  (
    {
      budget,
      className,
      topTTRef,
      animateTTLeft,
      animateTTRight,
      middleTTRef,
      animateTTLeftMiddle,
      animateTTRightMiddle,
      showAnchorTT,
    },
    ref
  ) => {
    const [clickedLink, setClickedLink] = useState('');

    const clickedLinkHandler = (number, name) => {
      setClickedLink(`${number}-${formatNameForID(name)}`);
    };

    return (
      <section
        ref={ref}
        className={`${styles['project-budget-parent']} ${className}`}
      >
        <TitleCard
          ref={topTTRef}
          costCodeFormat={budget.format}
          aniLeft={animateTTLeft}
          aniRight={animateTTRight}
        />

        <div className={styles['budget-inputs-parent']}>
          <AnchorLinks
            showAnchorTT={showAnchorTT}
            aniRight={animateTTLeftMiddle}
            onClickAnchor={clickedLinkHandler}
            divisions={budget.divisions}
            ref={middleTTRef}
          />
          <Budget
            aniRight={animateTTRightMiddle}
            clickedLink={clickedLink}
            budget={budget.divisions}
          />
        </div>
      </section>
    );
  }
);

export default ProjectBudget;
