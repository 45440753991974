import { useReducer } from 'react';

const initialState = {
  val: '',
  isTouched: false,
  isValid: false,
};

const inputReducer = (state, action) => {
  if (action.type === 'BLUR') {
    return {
      val: state.val,
      isTouched: true,
      isValid: action.isValid,
    };
  }
  if (action.type === 'INPUT') {
    return {
      val: action.val,
      isTouched: state.isTouched,
      isValid: action.isValid,
    };
  }

  if (action.type === 'RESET') {
    return { val: action.val, isTouched: action.isTouched, isValid: false };
  }

  return initialState;
};

const useInputValidation = (func) => {
  const [state, dispatch] = useReducer(inputReducer, initialState);

  const valueBlurHandler = () => {
    dispatch({
      type: 'BLUR',
      isValid: func(state.val),
    });
  };

  const valueChangeHandler = (e) => {
    dispatch({
      type: 'INPUT',
      val: e.target.value,
      isValid: func(e.target.value),
    });
  };

  const valueResetHandler = () => {
    dispatch({
      type: 'RESET',
      val: '',
      isTouched: false,
      isValid: false,
    });
  };

  return { state, valueBlurHandler, valueChangeHandler, valueResetHandler };
};

export default useInputValidation;
