/**
 * Formats the input number
 * @param {number|string} num - The number to be formatted
 * @param {boolean} [toCurrency=true] - Indicates whether the number should be formatted as currency
 * @return {string} - The formatted number as a string
 */
// (?<!\.) <- will not sork in safari
export const formatNumber = (num, toCurrency = true, total = false) => {
  if (toCurrency && !total) {
    return num
      .toString()
      .replace(/[^\d.]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/\.\d{2}(\d+)/g, '$2');
  } else if (!toCurrency) {
    return num.replace(/[$\s,]/g, '').replace(/\.\d{2}(\d+)/g, '$2');
  } else if (toCurrency && total) {
    return Number(num.toString().replace(/[$\s,]/g, '')).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  }
};

/**
 * Formats a name to be used as an ID
 * @param {string} name - The name to format
 * @return {string} - The formatted name with all lowercase letters, spaces replaced with underscores, and ampersands replaced with "and"
 */
export const formatNameForID = (name) => {
  return name.toLowerCase().replaceAll(' ', '_').replaceAll('&', 'and');
};

/**
 * Formats a friendly name from an id name
 * @param {string} string - The name to format
 * @return {string} - The formatted friendly string.
 */
export const formatFriendly = (string) => {
  return string
    .replaceAll('-', ' ')
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};
