import { formatFriendly } from '../../../helpers/formatter';
import styles from './Dropdown.module.css';

const Dropdown = ({ label, input }) => {
  return (
    <div className={styles['input-box']}>
      <label className={styles['label']} htmlFor={input.id}>
        {label}
      </label>
      <select id={input.id} className={styles['input']}>
        {input.options.map((option, i) => {
          return (
            <option key={i} className={styles['input']} value={option}>
              {formatFriendly(option)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Dropdown;
