import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import Button from '../UI/Button';
import Card from '../UI/Card';
import { ReactComponent as UnderConstructionSVG } from '../../assets/svg_images/undraw_under_construction_-46-pa.svg';
import { ReactComponent as SpreadSheetSVG } from '../../assets/svg_images/undraw_spreadsheet_re_cn18.svg';
import { ReactComponent as BillSVG } from '../../assets/svg_images/undraw_receipt_re_fre3 1.svg';

import styles from './FeatureTile.module.css';

const FeatureTile = React.forwardRef((props, ref) => {
  const isFirst = props.featureNum === 0;
  const isSecond = props.featureNum === 1;
  const isThird = props.featureNum === 2;

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const navigate = useNavigate();

  // Because there is so much text in each feature tile
  // Putting all text in this component in a list and and noting
  // which feature it is as a prop
  const bodyContentArray = [
    <div className={`${styles['body-frame']} ${props.textAni}`}>
      <p>
        Stak utilizes machine learning technology to streamline the invoicing
        process, automatically reading and categorizing invoices to their
        corresponding projects, saving time and increasing efficiency for the
        user.
      </p>
      {/* <ul>
        <li>Verify the information</li>
        <li>Confirm the correct project</li>
        <li>And add necessary cost codes</li>
      </ul> */}
    </div>,
    <div
      className={`${styles['body-frame']} ${
        isSecond && styles['body-frame__two']
      } ${props.textAni}`}
    >
      <p>
        Stak streamlines budget management by automatically creating and
        updating project budgets, and generating a comprehensive spending report
        by division, subdivision, and cost code.
      </p>
    </div>,
    <div className={`${styles['body-frame']} ${props.textAni}`}>
      <p>
        With Stak, creating a client invoice is effortless. It automatically
        attaches all sub-contractor invoices and automatically sends reminders
        for prompt payment.
      </p>
    </div>,
  ];
  return (
    <Card ref={ref} className={`${styles['card-frame']} ${props.cardAni}`}>
      {isFirst && !isMobile && (
        <UnderConstructionSVG
          height={props.height}
          width={props.width}
          className={`${styles['background-image-svg-1']} ${props.svgAni}`}
        />
      )}
      {isSecond && !isMobile && (
        <SpreadSheetSVG
          height={props.height}
          width={props.width}
          className={`${styles['background-image-svg-2']} ${props.svgAni}`}
        />
      )}
      {isThird && !isMobile && (
        <BillSVG
          height={props.height}
          width={props.width}
          className={`${styles['background-image-svg-3']} ${props.svgAni}`}
        />
      )}
      <h3 className={`${styles['feature-title']} ${props.titleAni}`}>
        {props.title}
      </h3>
      {bodyContentArray[props.featureNum]}
      <Button
        className={`${styles['button__main']} ${
          isFirst && styles['button__first']
        } ${isSecond && styles['button__two']} ${
          isThird && styles['button__three']
        } ${props.buttonAni}`}
        buttonText="Learn More"
        onClick={() => navigate(`features/${props.goto}`)}
      />
    </Card>
  );
});

export default FeatureTile;
