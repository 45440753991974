// libs
import { useContext, useEffect, useRef, useState } from 'react';

// components
import { ReactComponent as BackgroundImg3 } from '../assets/svg_images/undraw_add_information_j2wg 1.svg';
import { ReactComponent as BackgroundImg4 } from '../assets/svg_images/undraw_observations_re_ohja 1.svg';
import Card from '../components/UI/Card';
import ProcessInvoices from '../components/ProcessInvoices/ProcessInvoices';
import ProcessInvoiceContext, {
  ProcessInvoiceContextProvider,
} from '../store/process-invoice-context';

// hooks
import useOnScreen from '../hooks/use-observer';

// css
import styles from './Pages.module.css';

const ProcessInvoice = () => {
  const [firstCardScrolledTo, setFirstDivScrolledTo] = useState(false);
  const [secondCardScrolledTo, setSecondCardScrolledTo] = useState(false);

  const invoiceCtx = useContext(ProcessInvoiceContext);
  const { lineItems } = invoiceCtx;

  const firstCardRef = useRef();
  const secondCardRef = useRef();

  const isFirstCardVisible = useOnScreen(firstCardRef);
  const isSecondCardVisible = useOnScreen(secondCardRef);

  useEffect(() => {
    if (isSecondCardVisible) setSecondCardScrolledTo(true);
  }, [isSecondCardVisible]);
  useEffect(() => {
    if (isFirstCardVisible) setFirstDivScrolledTo(true);
  }, [isFirstCardVisible]);

  return (
    <div className={styles['top-wrapper']}>
      {/* <div className={styles['background-img-3']}>
        <BackgroundImg3 />
      </div>
      {lineItems && (
        <div className={styles['background-img-4']}>
          <BackgroundImg4 />
        </div>
      )} */}
      <div className={`${styles['page-frame']}`}>
        <h1
          ref={firstCardRef}
          className={`${styles['title']} ${styles['title__main']} ${
            firstCardScrolledTo && styles['animate']
          }`}
        >
          Processing an Invoice is Easy with Stak!
        </h1>
        <div className={styles['example-invoice']}>
          <p className={styles['example-invoice__text']}>
            With Stak, categorizing invoices is a breeze. Simply drop the
            invoice PDF into our state-of-the-art model, and all necessary
            information is automatically populated in the user interface for
            easy organization and record keeping. Say goodbye to manual data
            entry and hello to streamlined invoicing.
          </p>
          <img
            src={require('../assets/images/invoice.jpg')}
            className={styles['example-invoice__img']}
            alt="example-invoice"
          />
        </div>
        <Card
          ref={secondCardRef}
          className={`${styles['card-frame']} ${
            secondCardScrolledTo && styles['animate']
          } ${styles['title']} ${styles['title__sub']}`}
        >
          Stak's invoicing system ensures accuracy by having the user review and
          inspect each section, add cost codes as necessary, then once approved
          is added to the client's invoice.
        </Card>

        <ProcessInvoiceContextProvider>
          <ProcessInvoices />
        </ProcessInvoiceContextProvider>
      </div>
    </div>
  );
};

export default ProcessInvoice;
