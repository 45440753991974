// libs
import React from 'react';

// components
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ReactComponent as StakLogo } from '../../assets/logo/stak_logo.svg';
import { HashLink } from 'react-router-hash-link';

// css
import styles from './NavComponent.module.css';

const NavComponent = (props) => {
  return (
    <Navbar
      className={`${styles['navbar-main']}`}
      bg="dark"
      variant="dark"
      expand="lg"
      fixed="top"
    >
      <Container>
        <HashLink to="/">
          <div className={styles['logo-container']}>
            <StakLogo className={styles['logo']} />
            <p>Stak</p>
          </div>
        </HashLink>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className={styles['nav-link-container']}>
            {/* <HashLink className="nav-link" to="/#about">
              About
            </HashLink> */}
            <NavDropdown
              title="Features"
              id="features-dropdown"
              className={styles['nav-dropdown']}
            >
              <LinkContainer
                activeClassName={styles['active-feature-page']}
                to="/features/process-invoices"
              >
                <NavDropdown.Item>Process Invoice</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer
                to="/features/update-budgets"
                activeClassName={styles['active-feature-page']}
              >
                <NavDropdown.Item>Update Budgets</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer
                to="/features/build-invoice"
                activeClassName={styles['active-feature-page']}
              >
                <NavDropdown.Item>Build Client's Invoice</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <a
              className={styles['login-button']}
              // href="http://localhost:3000/api/auth/login"
              // href="http://staging-app.stak.cc/api/auth/login"
              href="http://app.stak.cc/api/auth/login"
            >
              Login
            </a>
            {/* <button
              className={styles['login-button']}
              onClick={() => props.onShowModal()}
            >
              Contact Us
            </button> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
