/**
*
Refactors the Budget Object to be easily slices in Redux
@function
@param {Object} rawBudgetFromAPI - The budget object received from the API
@returns {Object} - The budget object with a new format
*/

const refactorBudget = (rawBudgetFromAPI) => {
  const divisionObj = {};
  rawBudgetFromAPI.divisions?.forEach((div) => {
    divisionObj[+div.number] = { ...div };
    const subdivObj = {};
    div.subdivisions?.forEach((subdiv) => {
      subdivObj[+subdiv.number] = { ...subdiv };
      divisionObj[+div.number].subdivisions = subdivObj;
      const itemsObj = {};
      subdiv.items?.forEach((item) => {
        itemsObj[+item.number] = { ...item };
        divisionObj[+div.number].subdivisions[+subdiv.number].items = itemsObj;
      });
    });
  });
  return {
    format: rawBudgetFromAPI.format,
    currency: rawBudgetFromAPI.currency,
    isCollapsed: rawBudgetFromAPI.isCollapsed,
    total: rawBudgetFromAPI.total,
    ...divisionObj,
  };
};

export default refactorBudget;
