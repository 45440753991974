// libs
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import InputToggleCombined from '../../UI/FormUI/InputToggleCombine';
import BudgetDivTitle from './BudgetDivTitle';
import BudgetSubDivTitle from './BudgetSubDivTitle';

// css
import styles from './Budget.module.css';

// helpers
import { formatNameForID, formatNumber } from '../../../helpers/formatter';
import TooltipStatic from '../../UI/TooltipStatic';

const Budget = (props) => {
  const scrollableRef = useRef();
  const budgetTotal = useSelector((state) => state.total);

  // Scroll to the anchor link point only when the anchor link
  // that was clicked changes.
  useEffect(() => {
    if (scrollableRef.current) {
      const scrollToElement = document.getElementById(`${props.clickedLink}`);
      if (scrollToElement) {
        const topScrollAnchor = document
          .getElementById('01-general_conditions')
          .getBoundingClientRect().top;
        const parentScrollElement = document.getElementById('scroll-frame');
        parentScrollElement.scroll({
          top: scrollToElement.getBoundingClientRect().top - topScrollAnchor,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [props.clickedLink]);

  const divisionBudgetJSX = [];
  props.budget.forEach((div, i) => {
    const innerBudgetJSX = [];
    // if (i > 1) return; // REMOVE!!! test case here because there are no other divisions
    const divisionName = div.name;
    const divisionNumber = div.number;

    // can add total here if we want by calling that total function on the division
    innerBudgetJSX.push(
      <BudgetDivTitle
        id={`${divisionNumber}-${formatNameForID(divisionName)}`}
        divisionNumber={divisionNumber}
        divisionName={divisionName}
        className={styles['division-frame__division']}
        key={`${divisionNumber}-${formatNameForID(divisionName)}`}
      />
    );
    div.subdivisions?.forEach((subdiv) => {
      const subdivName = subdiv.name;
      const subdivNumber = subdiv.number;

      const totalValue = subdiv.total;
      const isAllEmpty = totalValue === 0;

      innerBudgetJSX.push(
        <BudgetSubDivTitle
          subdivNumber={subdivNumber}
          subdivName={subdivName}
          id={`${subdivNumber}-${formatNameForID(subdivName)}`}
          className={styles['division-frame__subdivision']}
          key={`${subdivNumber}-${formatNameForID(subdivName)}`}
          isAllEmpty={isAllEmpty}
          divNumber={divisionNumber}
        />
      );
      subdiv.items?.forEach((item) => {
        const itemName = item.name;
        const itemNumber = item.number;
        innerBudgetJSX.push(
          <InputToggleCombined
            className={styles['division-frame__items']}
            id={`${itemNumber}-${formatNameForID(itemName)}`}
            key={itemNumber}
            divNumber={divisionNumber}
            subdivNumber={subdivNumber}
            itemNumber={itemNumber}
            label={`${itemNumber} - ${itemName}`}
            input={{
              id: itemNumber,
              type: 'text',
            }}
          />
        );
      });
    });
    divisionBudgetJSX.push(
      <section className={styles['division-frame']} key={divisionNumber}>
        {innerBudgetJSX}
      </section>
    );
  });

  return (
    <div className={styles['budget-parent-frame']}>
      <section className={styles['total']}>
        Total: $ {formatNumber(budgetTotal, true, true)}
      </section>
      <TooltipStatic
        content="Stak makes budget management a breeze with the ability to add or remove any cost code and instantly update totals, giving you full control over your project's finances. Try it out for yourself and experience the ease of Stak."
        direction="right"
        animateTT={props.aniRight}
      >
        <div
          id="scroll-frame"
          className={styles['budget-scroll-frame']}
          ref={scrollableRef}
        >
          <div className={styles['budget-content-frame']}>
            {divisionBudgetJSX}
          </div>
        </div>
      </TooltipStatic>
    </div>
  );
};

export default Budget;
