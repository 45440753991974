import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// components
import Card from '../../UI/Card';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Info.svg';
import Button from '../../UI/Button';

//redux
import { budgetActions } from '../../../store';

// css
import styles from './Title.module.css';
import TooltipStatic from '../../UI/TooltipStatic';

const Title = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const isCollapsed = useSelector((state) => state.isCollapsed);
  const collapseButtonText = isCollapsed ? 'Expand Budget' : 'Collapse Budget';

  const collapseHandler = () => {
    dispatch(budgetActions.toggleCollapse());
  };

  return (
    <Card className={styles['title-card']}>
      <div className={styles['title-card__text']}>
        <h2>Project Budget</h2>
        <div className={styles['title-card__subtitle']}>
          <TooltipStatic
            content="Stak implements your company's custom cost code format."
            direction="left"
            animateTT={props.aniRight}
            ref={ref}
          >
            <h3>Cost Code Format:&nbsp;</h3>
            <h3>{props.costCodeFormat}&nbsp;</h3>
          </TooltipStatic>
          <div>
            <InfoIcon width={isMobile ? 15 : 20} />
          </div>
        </div>
      </div>
      <div className={styles['title-card__buttons']}>
        <Button buttonText="Save" type="text" />
        <TooltipStatic
          content="Easily manage your budget with Stak, by expanding or collapsing to view all cost codes, and quickly add or remove them with a single click. Try it out here!"
          direction="right"
          animateTT={props.aniLeft}
        >
          <Button buttonText={collapseButtonText} onClick={collapseHandler} />
        </TooltipStatic>
      </div>
    </Card>
  );
});

export default Title;
