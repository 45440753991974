// libs
import { useContext } from 'react';
import { formatNumber } from '../../helpers/formatter';

import ProcessInvoiceContext from '../../store/process-invoice-context';
import Button from '../UI/Button';

// components
import Dropdown from '../UI/FormUI/Dropdown';
import InputApprovers from '../UI/FormUI/InputApprovers';
import InputProcessInvoice from '../UI/FormUI/InputProcesInvoice';
import InputSlideToggle from '../UI/FormUI/InputSlideToggle';
import TooltipDyn from '../UI/TooltipDyn';
import LineItems from './LineItems';

import styles from './ProcessInvoices.module.css';

const dummyInvoice = {
  invoiceNumber: '',
  vendorName: 'XYZ Construction',
  project: 'Smith Lakehouse',
  costCode: '',
  totalTax: formatNumber('0', true, true),
  totalAmount: formatNumber('10600', true, true),
  lineItems: [
    {
      description:
        'EXCAVATE & BACKFILL NEW ADDITION @ 100% COMPLETE CONTRACT 7860.00',
      costCode: '',
      total: formatNumber('3930', true, true),
      changeOrder: false,
      isCurrency: true,
    },
    {
      description: 'DRAINTILE DOWNSPOUTS @ 100% COMPLETE CONTRACT 5000.00',
      costCode: '',
      total: formatNumber('5000', true, true),
      changeOrder: false,
      isCurrency: true,
    },
    {
      description: 'LOCATE EXPOSE EXIST SEWER @ 100% COMPLETE CONTRACT 1670',
      costCode: '',
      total: formatNumber('1670', true, true),
      changeOrder: false,
      isCurrency: true,
    },
  ],
  approvers: ['Approver 1'],
  changeOrder: false,
  credit: false,
  addToInvoice: true,
};

const ProcessInvoices = () => {
  const invoiceCtx = useContext(ProcessInvoiceContext);
  const { lineItems } = invoiceCtx;

  return (
    <div className={styles['process-invoice']}>
      <div style={{ position: 'relative', width: '100%' }}>
        <TooltipDyn
          content="This invoice has no invoice number"
          direction="right"
        >
          <InputProcessInvoice
            label="Invoice Number"
            input={{
              id: 'invoice-number',
              type: 'text',
              modelval: dummyInvoice['invoiceNumber'],
            }}
          />
        </TooltipDyn>
      </div>

      <div style={{ position: 'relative', width: '100%' }}>
        <TooltipDyn
          content="Vendor name picked up from the invoice (changed for privacy ;). If there is a contract with this vendor, clicking this button will show all contract details!"
          direction="right"
        >
          <InputProcessInvoice
            label="Vendor Name"
            input={{
              id: 'vendor-name',
              type: 'text',
              modelval: dummyInvoice['vendorName'],
            }}
            buttonText="Contract"
            isButton={true}
          />
        </TooltipDyn>
      </div>

      <div style={{ position: 'relative', width: '100%' }}>
        <TooltipDyn
          content="If the invoice is sorted to an incorrect project, it can be easily changed here."
          direction="right"
        >
          <Dropdown
            label="Project"
            input={{
              id: 'vendor-name',
              options: ['smith-lakehouse', 'richardson', 'clark'],
            }}
          />
        </TooltipDyn>
      </div>

      <div style={{ position: 'relative', width: '100%' }}>
        <TooltipDyn
          content="Click here to see all line items for this invoice, go ahead click it!"
          direction="right"
        >
          <InputSlideToggle
            label="Line Items"
            ctxId="lineItems"
            input={{
              id: 'line-items',
            }}
          />
        </TooltipDyn>
      </div>

      {lineItems && <LineItems lineItems={dummyInvoice['lineItems']} />}
      <div style={{ position: 'relative', width: '100%' }}>
        <TooltipDyn
          content="Categorize the entire invoice, or each line item with the correct cost code."
          direction="right"
        >
          {!lineItems && (
            <InputProcessInvoice
              label="Cost Code"
              input={{ id: 'cost-code', type: 'text' }}
            />
          )}
        </TooltipDyn>
      </div>

      <InputProcessInvoice
        label="Total Tax"
        isCurrency={true}
        input={{
          id: 'total-tax',
          type: 'text',
          modelval: dummyInvoice['totalTax'],
        }}
      />
      <InputProcessInvoice
        label="Total Amount"
        isCurrency={true}
        input={{
          id: 'cost-code',
          type: 'text',
          modelval: dummyInvoice['totalAmount'],
        }}
      />
      <div style={{ position: 'relative', width: '100%' }}>
        <TooltipDyn
          content="Once an invoice is processed, it needs final approval before getting added to the client's invoice."
          direction="right"
        >
          <InputApprovers
            label="Approvers"
            input={{ id: 'approvers', approvers: dummyInvoice['approvers'] }}
          />
        </TooltipDyn>
      </div>
      <div className={styles['process-footer']}>
        <div className={styles['footer-sliders']}>
          <InputSlideToggle
            label="Change Order"
            ctxId="changeOrder"
            input={{
              id: 'change-order',
              startVal: dummyInvoice['changeOrder'],
            }}
          />
          <InputSlideToggle
            label="Credit"
            ctxId="credit"
            input={{
              id: 'credit',
              startVal: dummyInvoice['credit'],
            }}
          />
          <InputSlideToggle
            label="Add to Current Invoice"
            ctxId="addToInvoice"
            input={{
              id: 'add-to-invoice',
              startVal: dummyInvoice['addToInvoice'],
            }}
          />
        </div>
        <Button className={styles['button']} buttonText="Next Invoice" />
      </div>
      {/* </div> */}
    </div>
  );
};

export default ProcessInvoices;
