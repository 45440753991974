import React from 'react';

import styles from './DrillDownExample.module.css';

import { ReactComponent as SiteWorkPlot } from '../../assets/svg_images/02-b2a-blu-green 1.svg';
import { ReactComponent as EarthworkPlot } from '../../assets/svg_images/22-b2a-blu-green 2.svg';
import B2APlotTile from './B2APlotTile';
import TooltipStatic from '../UI/TooltipStatic';

const DrillDownExample = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={`${styles['wrapper-frame']} ${props.className}`}>
      <div style={{ position: 'relative', padding: 0, margin: 0 }}>
        <TooltipStatic
          content="Clicking on the 'Earthwork' bars in the Site Work Division will reveal a second plot showing the individual cost codes within that subdivision, giving you a complete understanding of the project's financial progress."
          direction="left"
        >
          <B2APlotTile title="Site Work" number="02">
            <SiteWorkPlot className={styles['svg']} />
          </B2APlotTile>
        </TooltipStatic>
      </div>
      <div style={{ position: 'relative' }}>
        <TooltipStatic
          content="With the ability to view how much has been spent for each cost code within the 'Earthwork' subdivision, Stak allows easy tracking and management of project expenses."
          direction="right"
        >
          <B2APlotTile title="Site Work > Earthwork" number="2.2">
            <EarthworkPlot className={styles['svg']} />
          </B2APlotTile>
        </TooltipStatic>
      </div>
    </div>
  );
});

export default DrillDownExample;
