// libs
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

// components
import BudgetInputRedux from './BudgetInputRedux';
import ToggleBudgetInput from './ToggleBudgetInput';

const InputToggleCombined = (props) => {
  const { divNumber, subdivNumber, itemNumber } = props;

  const isShowing = useSelector(
    (state) =>
      state[+divNumber].subdivisions[+subdivNumber].items[+itemNumber].isShowing
  );

  const isCollapsed = useSelector((state) => state.isCollapsed);

  return (
    <Fragment>
      {(!isCollapsed || isShowing) && (
        <div className={props.className} id={props.id}>
          <BudgetInputRedux
            label={props.label}
            input={props.input}
            divNumber={+divNumber}
            subdivNumber={+subdivNumber}
            itemNumber={+itemNumber}
          />
          <ToggleBudgetInput
            divNumber={+divNumber}
            subdivNumber={+subdivNumber}
            itemNumber={+itemNumber}
          />
        </div>
      )}
    </Fragment>
  );
};

export default InputToggleCombined;
